<template>
  <div>
    <DataTable
      :value="data"
      :loading="loading"
      :paginator="pagination"
      :rows="perPage"
      :dataKey="dataKey"
      :paginatorTemplate="paginationTemplate"
      :rowsPerPageOptions="perPageOption"
      currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
      :scrollable="false"
      scrollDirection="horizontal"
      v-model:filters="state.filters"
      ref="dt"
      :autoLayout="autoLayout"
      responsiveLayout="scroll"
      breakpoint="640px"
      :export-filename="state.downloadFileName"
    >
      <template #header v-if="showExport || showSearch">
        <div
          class="d-flex"
          :class=" showExport ? 'justify-content-between' : 'justify-content-end'"
        >
          <Button v-if="showExport" icon="pi pi-external-link" label="Export" @click="exportCsv($event)" />

          <div v-if="showSearch">
              <span class="p-input-icon-left">
                <i class="pi pi-search" />
                <InputText v-model="state.filters['global'].value" placeholder="Search" />
              </span>
          </div>

        </div>
      </template>
      <Column
        v-for="(column, index) in columns"
        :key="`dataTable-${index}`"
        :field="column.field"
        :sortable="column['sortable']"
      >
        <template #header>
          {{ column.header }}
        </template>

        <template
          #body="{ data }"
        >

          <span
            @click="fireEvent(column['event'], data)"
          >
            <slot
              :name="column.field"
              :item="data"
              @click="fireEvent(column['event'], data)"
            >
              {{ data[column.field] }}
            </slot>
          </span>

        </template>
      </Column>

      <Column header="Action" v-if="showActions">

        <template #body="{data}">

          <slot v-bind:item="data" name="action">

            <span
              v-if="showView"
              type="button"
              class="order-action list-action"
              @click="fireEvent(LIST_ITEM_ACTION_VIEW, data)"
            >
            <i class="icon-archive"></i>
          </span>

            <span
              type="button"
              class="order-action list-action"
              v-if="showEdit"
              @click="fireEvent(LIST_ITEM_ACTION_UPDATE, data)"
            >
            <i class="icon-pencil"></i>
          </span>

            <span
              type="button"
              class="order-action list-action"
              v-if="showDelete"
              @click="fireEvent(LIST_ITEM_ACTION_DELETE, data)"
            >
            <i class="icon-delete_sweep"></i>
          </span>

            <span
              type="button"
              class="order-action list-action"
              v-if="showPrint"
              @click="fireEvent(LIST_ITEM_ACTION_PRINT, data)"
            >
            <i class="icon-print"></i>
          </span>

          </slot>

        </template>

      </Column>

      <template #empty v-if="noDataMsg">
        {{ noDataMsg }}
      </template>

      <template #loading>
        Loading Data... please wait
      </template>

    </DataTable>
  </div>
</template>

<script>
import DataTable from 'primevue/datatable';
import InputText from 'primevue/inputtext';
import Button from 'primevue/button';
import Column from 'primevue/column';
import {
  LIST_ITEM_ACTION_DELETE,
  LIST_ITEM_ACTION_PRINT,
  LIST_ITEM_ACTION_UPDATE,
  LIST_ITEM_ACTION_VIEW
} from '@/utils/constants';
import { FilterMatchMode } from 'primevue/api';
import { computed, reactive, ref } from 'vue';

export default {
  name: 'ItemTable',
  props: {

    columns: {
      type: [Array, Object],
    },

    data: {
      type: [Array, Object]
    },

    loading: {
      default: false,
      type: Boolean,
    },

    perPage: {
      type: Number,
      default: 10,
    },

    dataKey: {
      type: String,
      default: 'id'
    },

    perPageOption: {
      type: Array,
      default: () => [10, 20, 30, 40, 50]
    },

    pagination: {
      type: Boolean,
      default: true,
    },

    paginationTemplate: {
      type: String,
      default: 'FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown'
    },

    noDataMsg: {
      type: String,
      default: 'No Data Found'
    },

    showActions: {
      type: Boolean,
      default: true
    },

    showPrint: {
      type: Boolean,
      default: false,
    },

    showEdit: {
      type: Boolean,
      default: true,
    },

    showView: {
      type: Boolean,
      default: true
    },

    showDelete: {
      type: Boolean,
      default: true
    },

    showSearch: {
      type: Boolean,
      default: true
    },

    titleText: {
      type: String,
      required: false
    },

    showExport: {
      type: Boolean,
      default: false
    },
    exportFileName: {
      type: String,
      default: 'exportData'
    },
    exportData: {
      type: Array,
      default: () => []
    },

    bodyColumnPadding: {
      type: String,
      default: '10px 13px'
    },

    autoLayout: {
      type: Boolean,
      default: true
    },
  },
  components: {
    DataTable,
    Column,
    InputText,
    Button
  },

  setup (props, { emit }) {

    const state = reactive({
      filters: {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      },
      downloadFileName: computed(() => {
        return `${props.exportFileName}_${(new Date()).toLocaleDateString().replaceAll('/', '-')}`;
      })
    });

    const fireEvent = (event, data) => {
      if (!event) return;

      emit(event, data);
    };

    const dt = ref();
    const exportCsv = () => {
      if (props.exportData.length) {
        return dt.value.exportCSV(null, props.exportData)
      }

      return dt.value.exportCSV();
    };

    return {
      LIST_ITEM_ACTION_DELETE,
      LIST_ITEM_ACTION_VIEW,
      LIST_ITEM_ACTION_UPDATE,
      LIST_ITEM_ACTION_PRINT,
      dt,
      fireEvent,
      state,
      exportCsv
    };
  },

};
</script>

<style lang="scss" scoped>
@import "src/assets/sass/variables";
.p-column-title {
  display: none;
}

::v-deep(.p-datatable) {

  .p-sortable-column {

    .p-sortable-column-icon {
      color: $title-color;
    }

    &:not(.p-highlight) {

      &:hover {
        background-color: $data-table-header-bg;
        color: #e2e2e2;

        .p-sortable-column-icon {
          color: $breadcrumb-item-color;
        }

      }
    }
  }

  .p-datatable-thead > tr > th {
    background-color: $primary;
    color: $title-color;
    font-size: 20px !important;
    text-transform: uppercase !important;
    text-align: center;
  }
}

::v-deep(.p-datatable) {

  .p-datatable-tbody {
    tr {
      background: $data-table-bg;
      color: $title-color;
      td {
        padding: v-bind('bodyColumnPadding');
        border: 1px solid $data-table-border;
        border-width: 0 0 1px 0;
        font-size: 18px;
        text-transform: uppercase;
      }
    }
  }

  .p-datatable-thead {
    tr {
      th {
        padding: 1rem;
      }
    }
  }

}

::v-deep(.p-paginator) {
  .p-paginator-current {
    margin-left: auto;
  }
}
</style>
